import Swal from "sweetalert2/dist/sweetalert2.js";
import Head from "next/head";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { API_BASE_URL, API_BASEMOBIL_URL } from "../../libs/generalSettings";
import { postJSON, get } from "../../libs/services/request";
import Language from "../../locales/GetLanguageResourceResponse.json";
import { login, logout } from "../../redux/stores/auth";
import { encryptJson, decrypt } from "../../libs/encryption";
import { useRouter } from "next/router";
import { IsNullOrEmpty } from "../../libs/utils";
import Link from "next/link";
import Loader from "../../components/Loader";
import Image from "next/image";
import basket from "../../redux/stores/basket";
const LoginPage = () => {
  const router = useRouter();
  const [email, setUsername] = useState("");
  const [sifre, setPassword] = useState("");
  const [captcha, SetCaptcha] = useState([]);
  const [loaderIsShow, setLoaderIsShow] = useState(false);
  const [callbackUrl, setCallbackUrl] = useState("");

  const [text, setText] = useState("");
  const [userLogin, setUserLogin] = useState(false);
  const languageStore = useSelector((state) => state.language);
  const isLoggin = decrypt(useSelector((state) => state.auth.loggedIn));
  useEffect(() => {
    setUserLogin(isLoggin);
  }, [isLoggin]);
  const dispatch = useDispatch();
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Enter'ın varsayılan davranışını engelle
      handleLogin(); // Butonun tıklanma işlemini tetikle
    }
  };
  const handleLogin = async (e) => {
    debugger;
    setLoaderIsShow(false);
    const validateParams = {
      sentText: text,
      uuid: captcha.uuid,
    };
    if (!IsNullOrEmpty(text)) {
      const base64String = Buffer.from(captcha.uuid + ";" + text).toString(
        "base64"
      );

      const loginParams = {
        username: email,
        password: sifre,
      };
      if (
        !IsNullOrEmpty(loginParams.username) ||
        !IsNullOrEmpty(loginParams.password)
      ) {
        postJSON(
          `${API_BASEMOBIL_URL}/users/login?captchaDatasBase64=${base64String}`,
          loginParams
        ).then((result) => {
          {
            setLoaderIsShow(true);
            debugger;
            if (result.sonuc === true) {
              dispatch(login(result));

              router.push("/");
              Swal.fire({
                title: Language[languageStore].KullaniciGirisiBasarili,
                icon: "success",
                timer: 2000,
                timerProgressBar: true,
                didOpen: () => {
                  Swal.showLoading();
                },
              });
            } else if (result.sonucKodu === "07") {
              setLoaderIsShow(true);
              GetCaptcha();
              Swal.fire({
                title: Language[languageStore].GuvenlikKoduHata,
                icon: "error",
                timer: 2000,
                timerProgressBar: true,
                didOpen: () => {
                  Swal.showLoading();
                },
              });
            } else {
              setLoaderIsShow(true);
              GetCaptcha();
              Swal.fire({
                title: Language[languageStore].KullaniciGirisiHata,
                icon: "error",
                timer: 2000,
                timerProgressBar: true,
                didOpen: () => {
                  Swal.showLoading();
                },
              });
            }
          }
        });
      } else {
        setLoaderIsShow(true);

        Swal.fire({
          title: Language[languageStore].KullaniciBilgileriBosGecilemez,
          icon: "error",
          timer: 2000,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading();
          },
        });
      }
    } else {
      setLoaderIsShow(true);

      Swal.fire({
        title: Language[languageStore].GuvenlikKoduBosGecilemez,
        icon: "error",
        timer: 2000,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
        },
      });
    }
  };

  const handleLogout = async () => {
    dispatch(logout());
  };
  useEffect(() => {
    (async () => {
      await get(`${API_BASE_URL}/getCaptcha`).then((result) => {
        {
          if (result.sonuc === true) {
            SetCaptcha(result.data);
            setLoaderIsShow(true);
          } else {
          }
        }
      });
    })();
  }, []);
  useEffect(() => {
    (async () => {
      await get(`${API_BASE_URL}/getEdevletLoginUrl`).then((result) => {
        {
          if (result.sonuc === true) {
            setCallbackUrl(result.data);
            setLoaderIsShow(true);
          } else {
          }
        }
      });
    })();
  }, []);
  const GetCaptcha = async () => {
    await get(`${API_BASE_URL}/getCaptcha`).then((result) => {
      {
        if (result.sonuc === true) {
          SetCaptcha(result.data);
          setLoaderIsShow(true);
        } else {
        }
      }
    });
  };

  return (
    <>
      <Head>
        <title>
          {Language[languageStore].webTitle} |{" "}
          {Language[languageStore].loginTitle}
        </title>
      </Head>
      {!loaderIsShow ? <Loader /> : ""}

      <section className="section-box mt-60">
        <div className="container">
          <div className="row mb-100">
            <div className="col-lg-1" />
            {userLogin ? (
              <button onClick={handleLogout}>Çıkış Yap</button>
            ) : (
              <div className="col-lg-5">
                <h3>{Language[languageStore].loginTitle}</h3>
                <p className="font-md color-gray-500">
                  {Language[languageStore].loginSloganTitle}
                </p>

                <div>
                  {" "}
                  <div className="form-register mt-30 mb-30">
                    <div className="form-group">
                      <label className="mb-5 font-sm color-gray-700">
                        {Language[languageStore].loginEpostaTitle} *
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="pttfilateli@gmail.com"
                        value={email}
                        onKeyDown={handleKeyDown}
                        required
                        onChange={(e) => setUsername(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <label className="mb-5 font-sm color-gray-700">
                        {Language[languageStore].loginSifreTitle} *
                      </label>
                      <input
                        className="form-control"
                        type="password"
                        placeholder="******************"
                        value={sifre}
                        onKeyDown={handleKeyDown}
                        required
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        {" "}
                        <div className="form-group">
                          <label className="color-gray-500 font-xs">
                            <label className="mb-5 font-sm color-gray-700">
                              *
                            </label>
                            <img
                              className="form-control"
                              src={
                                captcha.token &&
                                `data:image/jpeg;base64, ${captcha.token}`
                              }
                              style={{ height: 70 }}
                              alt="Base64 Image"
                            />{" "}
                            <a
                              href="#"
                              onClick={() => GetCaptcha()}
                              className="form-control btn btn-info font-xs mt-2"
                            >
                              {Language[languageStore].captchaYenileTitle}{" "}
                            </a>
                          </label>
                        </div>
                      </div>

                      <div className="col-md-8">
                        <div className="form-group">
                          <label className="mb-5 font-sm color-gray-700">
                            {Language[languageStore].GuvenlikKodu} *
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="captcha"
                            onKeyDown={handleKeyDown}
                            style={{ height: 70 }}
                            required
                            placeholder={Language[languageStore].GuvenlikKodu}
                            onChange={(e) => setText(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label className="color-gray-500 font-xs">
                            <input className="checkagree" type="checkbox" />
                            {Language[languageStore].loginBeniHatirlaTitle}
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-6 text-end">
                        <div className="form-group">
                          <Link
                            className="font-xs color-gray-500"
                            href="/recovery/"
                          >
                            {Language[languageStore].loginSifremiUnuttumTitle}
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        {" "}
                        <button
                          className="font-md-bold btn btn-buy"
                          type="submit"
                          onClick={handleLogin}
                        >
                          {Language[languageStore].loginGirisYapBtnTitle}
                        </button>
                      </div>{" "}
                      <div className="col-md-6">
                        <form
                          method="post"
                          className="edevlet-login-form"
                          action={callbackUrl}
                          target="_self"
                        >
                          <>
                            <button
                              className="font-md-bold btn btn-danger p-0"
                              type="submit"
                              style={{ width: "100%", height: 55 }}
                            >
                              <img
                                src="/static/imgs/edevlet-logo.png"
                                alt="E-Devlet Giriş Yap"
                                className="edevlet-login"
                                style={{ width: "85%", marginTop: 5 }}
                              />
                            </button>
                          </>
                        </form>
                      </div>
                    </div>
                    <div className="form-group"></div>
                    <div className="mt-20">
                      <Link
                        className="font-xs color-brand-3 font-medium"
                        href="/register/"
                      >
                        {" "}
                        <span className="font-xs color-gray-500 font-medium">
                          {Language[languageStore].loginHemenKayitOlTitle}
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="col-lg-5" />
          </div>
        </div>
      </section>
    </>
  );
};

export default LoginPage;
