import styles from "./styles.module.css";
import Image from "next/image";
import Bird from "../../public/static/imgs/template/bird.png";
import { IsNullOrEmpty } from "../../libs/utils";

function Loader() {
  return (
    <div className={styles.progress}>
      {!IsNullOrEmpty(Bird) && (
        <Image src={Bird ?? ""} width={200} height={120} alt="" title="" />
      )}
    </div>
  );
}

export default Loader;
